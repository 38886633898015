export const getCandyCaneVertexShader = () => `
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.0);
}
`;

export const getCandyCaneFragmentShader = () => `
uniform float iGlobalTime;
varying vec2 vUv;
uniform vec3 caneRed;
uniform vec3 caneWhite;
uniform vec3 background;
uniform float height;

int modOp(int a, int b) {
  return a - (b * int(a/b));
}


void main() {
  float stripeW = 30.0;
  float stripeH = 6.0;
  float topBarH = 25.0;
  // roll time offset into future, because of render "lag" near division remainder by zero (didn't bother to fix).
  float vt = iGlobalTime * 0.5 + 100000.0;
  int stripeRowIdx = int((-vt+gl_FragCoord.y) / stripeH);
  int stripeColIdx = int((gl_FragCoord.x-float(stripeRowIdx)*stripeW*0.20) / stripeW);
  bool renderStripe = modOp(stripeColIdx, 2) == 0;
  bool inRenderBox = gl_FragCoord.y >= (height-topBarH);
  gl_FragColor = !inRenderBox ? vec4(background, 1.0) :
                      renderStripe ? vec4(caneRed, 1.0) : vec4(caneWhite, 1.0);
}
`;
