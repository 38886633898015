import UnlockTimer from '../../components/UnlockTimer';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export const CURRENT_ADVENT_OF_CODE_YEAR: string = '2024' as const;

const FirstDayCountdown = () => {
	const navigate = useNavigate();
	const params = new URLSearchParams(window.location.search);
	const debugYear = params.get('debug_year') || null;
	const leaderboardToken = params.get('token') || null;
	const handleLeaderboardRedirect = useCallback(() => navigate(`/events/AOC-${debugYear || CURRENT_ADVENT_OF_CODE_YEAR}/leaderboard-tv${leaderboardToken ? `?token=${leaderboardToken}` : ''}`), [navigate, debugYear, leaderboardToken]);
	const [shouldGlow, setShouldGlow] = useState<boolean>(false);

	useEffect(() => {
		const onInterval = () => {
			setShouldGlow((v) => !v);
		};

		onInterval();
		const timer = setInterval(onInterval, 2000);

		return () => { clearInterval(timer); };
	}, []);
	const textShadow = shouldGlow ? '0 0 5px #4AF626' : 'none';

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontFamily: '\'Press Start 2P\', \'cursive\'', height: '100%', fontSize: '4rem' }}>
			<img style={{
				position: 'absolute',
				bottom: 0,
				paddingRight: '40vh',
				height: '30vh',
				opacity: 0.5,
			}} src="https://firebasestorage.googleapis.com/v0/b/inovait-advent-of-code.appspot.com/o/AOC_STATIC%2Fqr-bozicek.png?alt=media&token=1e68d584-8892-4272-9803-920f3675654a"></img>

			<img style={{
				position: 'absolute',
				bottom: 0,
				paddingLeft: '40vh',
				height: '30vh',
				opacity: 0.5,
			}} src="https://firebasestorage.googleapis.com/v0/b/inovait-advent-of-code.appspot.com/o/AOC_STATIC%2Fqr-slack-fix.png?alt=media&token=96d643d1-beb7-4139-89e3-c591ee2018d1"></img>

			<div style={{ margin: 50, marginTop: -150, fontSize: 35 }}><a style={{ textShadow }}>https://advent-of-code.inova.si</a></div>
			<div style={{ height: 0 }}>
				<UnlockTimer redirectToLeaderboard={handleLeaderboardRedirect} unlockDate={`${debugYear || CURRENT_ADVENT_OF_CODE_YEAR}-12-01T07:00:00`} funkyMode={true} />
			</div>
			{/* TO-DO: Add unlock event date for events, currently hardcoded for Advent Of Code(1st December) */}
		</div>
	);
};

export default FirstDayCountdown;
