import { createContext, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import { User } from 'firebase/auth';
import useAuth from '../hooks/useAuth';
import { styled } from '@mui/system';
import ProtectedRoute from './ProtectedRoute';
import EventDetails from './EventDetails';
import Tasks from './Tasks';
import TaskDetails from './TaskDetails';
import SnowBackground from './SnowBackground';
import Landing from '../components/Landing';
import Credits from '../components/Credits';
import Rewards from '../components/Rewards';
import { HEADER_SIZE_PX } from '../styles/AOCTheme';
import Leaderboard from './Leaderboard';
import BasicLayout from './BasicLayout';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';
import { selectInfoDialog, showHideInfoDialog } from 'state/tasks/tasksSlice';
import Dialog from 'components/Dialog';
import FirstDayCountdown from './FirstDayCountdown';
import Rules from './Rules';
import EventsList from './EventsList';
import TaskPreview from './TaskPreview';
import TaskLeaderboard from './TaskLeaderboard/Index';
import Profile from './Profile';
import SantaAdmin from './SantaAdmin';
import { isUserInternal } from 'hooks/useIsInternalUser';

const prefix = 'App';
const classes = {
	snowBackgroundContainer: `${prefix}-snowBackgroundContainer`,
	mainContainer: `${prefix}-mainContainer`,
	contentContainer: `${prefix}-contentContainer`,
};
const StyledDiv = styled('div')(() => ({
	[`&.${classes.snowBackgroundContainer}`]: {
		position: 'fixed',
		width: '100%',
		height: '100%',
	},
	[`&.${classes.mainContainer}`]: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingTop: HEADER_SIZE_PX,
	},
	[`&.${classes.contentContainer}`]: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		height: '100%',
	},
}));

export const UserContext = createContext<User | null>(null);

function App() {
	const { user, isLoading } = useAuth();
	const dispatch = useDispatch();
	const infoDialog = useAppSelector(selectInfoDialog);
	const closeInfoDialog = useCallback(() => dispatch(showHideInfoDialog()), [dispatch]);

	if (isLoading) {
		return null;
	}

	return <UserContext.Provider value={user}>
		{infoDialog && <Dialog
			title={infoDialog.title}
			content={infoDialog.message}
			onClose={closeInfoDialog} />}
		{
			location.pathname === '/christmas-qwerty-santa' ? <SantaAdmin />
				: location.pathname === '/christmas-qwerty-panda' ? <TaskPreview /> : <>
					<StyledDiv className={classes.snowBackgroundContainer}><SnowBackground /></StyledDiv>
					<StyledDiv className={classes.mainContainer}>
						<StyledDiv className={classes.contentContainer}>
							<Routes>
								<Route path="/" element={<BasicLayout user={user} ><Landing /></BasicLayout>} />
								<Route path="/events" element={<ProtectedRoute><BasicLayout user={user} ><EventsList /></BasicLayout></ProtectedRoute>} />
								<Route path="/credits" element={<ProtectedRoute><BasicLayout user={user} ><Credits /></BasicLayout></ProtectedRoute>} />
								<Route path="/rules" element={<ProtectedRoute><BasicLayout user={user} ><Rules /></BasicLayout></ProtectedRoute>} />
								<Route path="/countdown" element={<FirstDayCountdown />} />
								<Route path="/profile" element={<ProtectedRoute><BasicLayout user={user} ><Profile /></BasicLayout></ProtectedRoute>} />
								<Route path="/events/:eventId/" element={<EventDetails />} >
									<Route path="tasks-preview" element={<ProtectedRoute><BasicLayout user={user} ><Tasks /></BasicLayout></ProtectedRoute>} />
									{isUserInternal(user) && <Route path="rewards" element={<ProtectedRoute><BasicLayout user={user} ><Rewards /></BasicLayout></ProtectedRoute>} />}
									<Route path="tasks/:taskId" element={<ProtectedRoute><BasicLayout user={user} ><TaskDetails /></BasicLayout></ProtectedRoute>} />
									<Route path="leaderboard" element={<ProtectedRoute><BasicLayout showProjectTitleAndDate={false} user={user} ><Leaderboard /></BasicLayout></ProtectedRoute>} />
									<Route path="leaderboard/tasks/:taskId" element={<ProtectedRoute><BasicLayout showProjectTitleAndDate={false} user={user} ><TaskLeaderboard /></BasicLayout></ProtectedRoute>} />
									<Route path="leaderboard-tv" element={<Leaderboard isTv={true} />} />
									<Route path="credits" element={<ProtectedRoute><BasicLayout user={user} ><Credits /></BasicLayout></ProtectedRoute>} />
									<Route path="rules" element={<ProtectedRoute><BasicLayout user={user} ><Rules /></BasicLayout></ProtectedRoute>} />
								</Route>
							</Routes>
						</StyledDiv>
					</StyledDiv>
				</>}
	</UserContext.Provider>;
}
export default App;
