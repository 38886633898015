import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { styled } from '@mui/system';
import { formatDate } from 'utils/dateUtils';

interface IUnlockTimer {
	unlockDate: string;
	funkyMode?: boolean;
	showPrecisionIfRequired?: boolean;
	includeAbsoluteTime?: boolean;
	fetchAllTasks?: () => void;
	redirectToLeaderboard?: () => void;
}

const StyledDiv = styled('div')(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		marginLeft: 0,
	},
}));

const colors = [
	'#970604',
	'#ebaa6a',
	'#ED3434',
	'#ce8852',
	'#00B341',
	'#DDC898',
];

const UnlockTimer: FC<IUnlockTimer> = (props) => {
	const { unlockDate, fetchAllTasks, redirectToLeaderboard, funkyMode, showPrecisionIfRequired, includeAbsoluteTime } = props;
	const [countdown, setCountdown] = useState<string>('');
	const [updateCounter, setUpdateCounter] = useState<number>(0);
	const [updateFloatCounter, setUpdateFloatCounter] = useState<number>(0);

	useEffect(() => {
		const onInterval = () => {
			const date = new Date(unlockDate);
			const dateTime = DateTime.fromJSDate(date);

			const difference = dateTime.diffNow(['days', 'hours', 'minutes', 'seconds']);

			if (difference.valueOf() <= 0) {
				fetchAllTasks?.();
				redirectToLeaderboard?.();

			} else {
				if (funkyMode) {
					setUpdateCounter((v) => v + 1);
				}
				if (difference.days > 0) {
					if (showPrecisionIfRequired) {
						setCountdown(difference.toFormat(`d \'${difference.days > 1 ? 'dni' : 'dan'}\'`));
					} else {
						setCountdown(difference.toFormat(`d \'${difference.days > 1 ? 'dni' : 'dan'}\' 'in' hh:mm:ss`));
					}
				} else {
					setCountdown(difference.toFormat('hh:mm:ss'));
				}
			};
		};

		onInterval();
		const timer = setInterval(onInterval, 1000);

		return () => clearInterval(timer);
	}, [fetchAllTasks, unlockDate, redirectToLeaderboard, funkyMode, showPrecisionIfRequired]);

	useEffect(() => {
		if (!funkyMode) {
			return () => undefined;
		}
		const onInterval = () => {
			setUpdateFloatCounter((v) => v + 10);
		};

		onInterval();
		const timer = setInterval(onInterval, 33);

		return () => { clearInterval(timer); };
	}, [funkyMode]);

	return (
		<StyledDiv>
			{
				!funkyMode ? (includeAbsoluteTime ? <>{(`${formatDate(unlockDate, 'EEE').replace('.', ',') } ${ formatDate(unlockDate, 'dd. LLLL y \'ob\' HH:mm')}`)} <i>(čez {countdown})</i></> : countdown) : countdown.split('').reduce((acc: any, curr: any) => {
					// spaces should not have its own color index, as shift in color should ignore them.
					const lastVal = acc.at(-1)?.i;

					if (curr === ' ') {
						return [...acc, { i: lastVal ?? 0, c: curr }];
					}

					return [...acc, { i: (lastVal ?? -1) + 1, c: curr }];
				}, []).map(({ i, c }, posIdx) => {
					const sSize = 10;
					const sFreq = 240;
					const waveFormulaValue = sSize + Math.floor((Math.sin((posIdx * sFreq + updateFloatCounter) / 1000 * Math.PI) * sSize));

					return <div style={{ float: 'left', marginTop: waveFormulaValue }} key={Math.random().toString()}><span style={{ color: colors[(updateCounter * colors.length + (i - updateCounter)) % colors.length] }} >{c === ' ' ? <>&nbsp;</> : c}</span></div>;
				})
			}
		</StyledDiv>);

};

export default UnlockTimer;
