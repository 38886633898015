export class AnimationFrameFpsLock {
	public onRender: () => void;
	private requiredDiffMs: number;
	private rafHandle: number;

	constructor(args: { maxFps: number; onRender: () => void }) {
    	this.requiredDiffMs = 1000 / args.maxFps;
    	this.onRender = args.onRender;
    	this.rafHandle = 0;
	}

	start() {
    	let lastRenderTs = 0;
    	const tolerance = 1;
    	const loop = (nowTs: number) => {
    		this.rafHandle = requestAnimationFrame(loop);
    		if (nowTs - lastRenderTs < this.requiredDiffMs - tolerance) {
    			return;
    		}
    		lastRenderTs = nowTs;
    		this.onRender();
    	};

    	loop(0);
	}

	destroy() {
    	cancelAnimationFrame(this.rafHandle);
	}
}
