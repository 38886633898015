import { createTheme } from '@mui/material';

export const CANDYCANE_RED = '#ab263d';
export const WHITE = '#ffffff';
export const SNOWFLAKE_COLOR = '#cccccc';
export const COMPLETED_SNOWFLAKE_COLOR = '#FFD700';
export const HEADER_SIZE_PX = 30;
export const HEADER_CANDYCANE_ROWS = 5; // should divide HEADER_SIZE_PX nicely.
export const MAX_CONTAINER_WIDTH_PX = 1000;
export const MIDNIGHT_SKY_BACKGROUND_COLOR = '#16151d';

export const PRIMARY = '#4AF626';

const theme = createTheme({
	typography: {
		fontFamily: [
			'monospace',
		].join(','),
	},
	palette: {
		primary: {
			main: PRIMARY,
		},
		background: {
			default: MIDNIGHT_SKY_BACKGROUND_COLOR,
		},
		text: {
			primary: WHITE,
		},
		success: {
			main: COMPLETED_SNOWFLAKE_COLOR,
		},
	},
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(0, 0, 0, 0.4)',
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				expandIconWrapper: {
					color: WHITE,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					'> svg': {
						verticalAlign: 'text-bottom',
					},
				},
				h2: {
					fontFamily: '\'Press Start 2P\', \'cursive\'',
					fontSize: '2.1rem',
					margin: '3rem 0',
					color: PRIMARY,
				},
				h3: {
					fontFamily: '\'Press Start 2P\', \'cursive\'',
					fontSize: '2.2rem',
				},
				h4: {
					fontFamily: '\'Press Start 2P\', \'cursive\'',
					textTransform: 'uppercase',
					fontSize: '1.6rem',
				},
				h5: {
					fontFamily: '\'Press Start 2P\', \'cursive\'',
					textTransform: 'uppercase',
					fontSize: '1.3rem',
				},
				body2: {
					fontFamily: '\'Press Start 2P\', \'cursive\'',
					fontSize: '0.7rem',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true,
				disableFocusRipple: true,
				disableElevation: true,
				disableTouchRipple: true,
			},
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					':after': {
						borderBottom: `2px solid ${PRIMARY}`,
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					'::before': {
						borderBottom: `2px solid ${PRIMARY}`,
					},
					':hover:not(.Mui-disabled):before': {
						borderBottom: `2px solid ${PRIMARY}`,
					},
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: WHITE,
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					':hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.3)',
						color: PRIMARY,
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					display: 'flex',
					flexDirection: 'row',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					backgroundColor: MIDNIGHT_SKY_BACKGROUND_COLOR,
					':hover': {
						backgroundColor: MIDNIGHT_SKY_BACKGROUND_COLOR,
					},
					'&.Mui-selected': {
						backgroundColor: `${MIDNIGHT_SKY_BACKGROUND_COLOR} !important`,
						':hover': {
							backgroundColor: MIDNIGHT_SKY_BACKGROUND_COLOR,
						},
					},
					'&.Mui-focusVisible': {
						backgroundColor: MIDNIGHT_SKY_BACKGROUND_COLOR,
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: MIDNIGHT_SKY_BACKGROUND_COLOR,
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: WHITE,
				},
			},
		},
	},
	mixins: {
		toolbar: {
			minHeight: HEADER_SIZE_PX,
			'@media (min-width:0px) and (orientation: landscape)': {
				minHeight: HEADER_SIZE_PX,
			},
			'@media (min-width:600px)': {
				minHeight: HEADER_SIZE_PX,
			},
		},
	},
});

export default theme;
