import { useEffect, useRef, useState } from 'react';
import { createAnimatorAsync } from './gallery-animator';
import { AnimationFrameFpsLock } from 'components/SnowBackground/animation-frame-fps-lock';

export function useAdventOfDesignDependencies(name: string | null | undefined, eventId: string, taskId: string, partNumber: number) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [galleryAvailable, setGalleryAvailable] = useState(false);

	useEffect(() => {

		if (name !== 'AdventOfDesign') {
			return;
		}

		if (!canvasRef.current) {
			return;
		}

		const animation = new AnimationFrameFpsLock({ maxFps: 60, onRender: () => null });

		createAnimatorAsync(eventId, taskId, partNumber, canvasRef).then(({ onRender, imageContainer }) => {
			animation.onRender = onRender;
			if (!imageContainer.emptySearchResult) setGalleryAvailable(true);
		});
		animation.start();

		return () => animation.destroy();
	}, [name, eventId, taskId, partNumber]);

	return { canvasRef, galleryAvailable, setGalleryAvailable };
}
