import { Typography, FormControl, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { getShirtForUser, setShirtForUser, getClaimableDates } from 'API/shirts';
import { styled } from '@mui/system';
import AOCError from 'API/AOCError';
import { showHideInfoDialog } from 'state/tasks/tasksSlice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { formatDate } from 'utils/dateUtils';
import { shirtsErrorHandler } from 'utils/shirtsErrorHandler';
import { EventDb } from 'types/Event';
import { useParams } from 'react-router-dom';
import { ParamTypes } from 'types/ParamTypes';
import { selectEvent } from 'state/events/eventsSlice';
import Loading from 'components/Loading';
import { useIsInternalUser } from 'hooks/useIsInternalUser';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const StyledP = styled('p')(() => ({
	fontFamily: '\'Press Start 2P\'',
	fontSize: '1.0rem!important',
}));

const StyledMenuItem = styled(MenuItem)(() => ({
	'&:hover': {
		backgroundColor: '#009900 !important',
	},
}));

const Rewards = () => {
	const dispatch = useAppDispatch();
	const [canGetShirt, setCanGetShirt] = useState<boolean | null>(null);
	const [isSavingShirt, setIsSavingShirt] = useState(false);
	const [shirtSize, setShirtSize] = useState('');
	const { eventId } = useParams() as ParamTypes;
	const event = useAppSelector((state) => selectEvent(state, eventId));
	const [claimableDates, setClaimableDates] = useState<EventDb>();
	const isInternalUser = useIsInternalUser();

	const handleChange = async (event: SelectChangeEvent) => {
		const size = event.target.value;

		try {
			setIsSavingShirt(true);
			await setShirtForUser(eventId, size);
			setShirtSize(size);
			dispatch(showHideInfoDialog({ title: 'Obvestilo ✨', message: 'Velikost majice shranjena.' }));
		} catch (e) {
			dispatch(showHideInfoDialog({ title: 'Error', message: 'Prišlo je do napake pri shranjevanju velikosti majice.' }));
		} finally {
			setIsSavingShirt(false);
		}
	};

	useEffect(() => {
		if (!isInternalUser) {
			return;
		}

		const checkIfUserClaimedTShirt = async () => {
			setCanGetShirt(null);
			try {
				const data = await getShirtForUser(eventId);

				if (data.canGetShirt) {
					setCanGetShirt(data.canGetShirt);
					setShirtSize(data.shirtSize);
				} else {
					setCanGetShirt(false);
				}

			} catch (e: any) {
				setCanGetShirt(false);
				if (e instanceof AOCError) {
					if (e.errorCode === 'USER_NOT_FOUND') {
						dispatch(showHideInfoDialog({ title: 'Error', message: 'User not found.' }));
					} else {
						shirtsErrorHandler(e);
					}
				} else {
					dispatch(showHideInfoDialog({ title: 'Error', message: e.message }));
				}
			}
		};

		const fetchTShirtClaimableDates = async () => {
			try {
				const date = await getClaimableDates(eventId);

				setClaimableDates({ startCollectingTShirtsDate: formatDate(date.claimableDate.startCollectingTShirtsDate, 'dd. LLLL y HH:mm'), endCollectingTShirtsDate: formatDate(date.claimableDate.endCollectingTShirtsDate, 'dd. LLLL y HH:mm') });
			} catch (e: any) {
				if (e instanceof AOCError) {
					if (e.errorCode !== 'COLLECTING_DATES_NOT_FOUND') {
						shirtsErrorHandler(e);
					}
				} else {
					dispatch(showHideInfoDialog({ title: 'Error', message: e.message }));
				}
			}

		};

		checkIfUserClaimedTShirt();
		fetchTShirtClaimableDates();

	}, [dispatch, eventId, isInternalUser]);

	const shirtsClaimable = Boolean(claimableDates?.startCollectingTShirtsDate && claimableDates?.endCollectingTShirtsDate);

	return <div style={{ margin: '16px 16px 16px 16px' }}>
		<StyledTypography variant='h4'>Nagrade:</StyledTypography>
		{!event ? <><br /><Loading /></> : <>
			<StyledP><span style={{ color: 'gold' }}>Sijajne glavne nagrade</span>, opisane tukaj, <span style={{ color: 'gold' }}>se podeljujejo le na podlagi skupnega števila točk <em>glavne lestvice</em>, ki jih tekmovalci dosežejo z uspešno oddajo nalog</span></StyledP>
			{event?.rewardsMessage ? <StyledP>* GLAVNA NAGRADA: <div dangerouslySetInnerHTML={{ __html: ` ${event?.rewardsMessage}` }}></div><p>! V kolikor bo zmagovalcev več, se bo odvilo žrebanje</p></StyledP> : <></>}
			<StyledP>* <span style={{ color: 'gold' }}>Vsak sodelujoči</span> (ki odda <em>vsaj eno pravilno rešitev</em>) <span style={{ color: 'gold' }}>prejme častno majico dogodka</span> <em>INOVA {event?.name}</em></StyledP>
			<StyledP>! Velikost majice se nastavi na portalu{shirtsClaimable ?
				` med ${claimableDates!.startCollectingTShirtsDate} in ${claimableDates!.endCollectingTShirtsDate}`
				: ', nekje na sredini decembra (o tem vas bomo obvestili na komunikacijskih kanalih podjetja)'}</StyledP>
			<br />
			{canGetShirt === null && shirtsClaimable && <Box> <StyledP>*** Samo trenutek... Sistem preverja, če že lahko izbereš majico ***</StyledP></Box>}
			{canGetShirt === false && shirtsClaimable && <Box> <StyledP style={{ color: 'red' }}>! Majice zaenkrat ne moreš izbrati, saj ne izpolnjuješ vseh pogojev</StyledP></Box>}
			{canGetShirt === true && shirtsClaimable && <Box>
				<FormControl>
					<StyledP style={{ color: 'gold' }}>Izbrana velikost majice: </StyledP>
					<FormControl disabled={isSavingShirt} sx={{ m: 0, paddingLeft: 1 }} variant="standard">
						<Select
							value={shirtSize}
							onChange={handleChange}
							style={{ fontFamily: '\'Press Start 2P\'' }}
						>
							{['Moški', 'Ženski'].map((gender) => ['xs', 's', 'm', 'l', 'xl', 'xxl'].map((size) => `${gender}-${size}`)).flat().map((tShirt) =>
								<StyledMenuItem key={tShirt} style={{ ...(tShirt === shirtSize ? { color: 'red' } : {}), fontFamily: '\'Press Start 2P\'' }} value={tShirt}>{tShirt}</StyledMenuItem>)}
						</Select>
					</FormControl>
				</FormControl>
			</Box>}
		</>}
	</div>;

};

export default Rewards;
